import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import DotPayForm from "./DotPayForm";
import PaymentAmount from "./PaymentAmount";
import {selectEventConfig} from "../../event_fetcher/EventFetcherSlice";
import Przelewy24Form from "./Przelewy24Form";
import ReactGA from "react-ga";

const PaymentChooser = ({sender, letterId}) => {
  const [selectedAmount, setSelectedAmount] = useState(false);
  const [customAmount, setCustomAmount] = useState("");
  const {event} = useSelector(selectEventConfig);
  if (!sender) {
    sender = {
      first_name: "Test First Name",
      last_name: "Test Last Name",
      email: "m.mojek@gmail.com",
      postal_code: "00-175",
    };
  }

  // : {
  //   payment_account,
  //   payament_amount_one,
  //   payament_amount_two,
  //   minimum_amount,
  // },
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: {errors},
  } = useForm({
    defaultValues: {
      description: event && event.payment_account,
      firstname: sender.first_name,
      lastname: sender.last_name,
      email: sender.email,
      amountChosen: undefined,
      // postcode: sender.postal_code,
      source: "santa_letter",
      // ignore_last_payment_channel: 1,
      // api_version: "dev",
      // currency: "PLN",
      success_url: `${process.env.REACT_APP_DOMAIN}/dziekujemy/l/${letterId}/amt/0`,
      // urlc: `${process.env.REACT_APP_API_DOMAIN}/urlc`,
      // type: 4,
      product_id: letterId,
    },
  });
  useEffect(() => {
    if (event) {
      register("amount", {required: true, min: event.minimum_amount});
    }
  }, [event]);

  const updateUrlAfterAmountSet = (amount) => {
    const amountRegex = /amt\/[0-9.]*/gm;
    const url = getValues("success_url");
    const urlUpdated = url.replace(amountRegex, "amt/" + amount);
    setValue("success_url", urlUpdated);
  };
  const handleSelectPayment = (amountVal) => {
    setSelectedAmount(amountVal);
    setValue("amount", "");
    console.log("amountVal", amountVal);
    if (amountVal !== "other") {
      setValue("amount", amountVal, {shouldValidate: true});
      let amountChosen
      if (amountVal === "20") amountChosen = 1
      if (amountVal === "35") amountChosen = 2

      setValue("amountChosen", amountChosen);
      ReactGA.event({
        category: 'Wyślij list',
        action: "Przekaż darowiznę i czekaj na list",
        label: 'Kwota: ' + amountChosen,
        value: +amountVal
      });
    } else {
      setValue("amountChosen", 3);
      if (customAmount) {
        setValue("amount", customAmount, {shouldValidate: true});

      }
    }
    updateUrlAfterAmountSet(getValues("amount"));
  };
  const handleOther = (e) => {
    // const amountPreparation = e.target.value.replace(",", ".");
    const amountPreparation = e.target.value;
    setValue("amount", e.target.value, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setCustomAmount(e.target.value);
    updateUrlAfterAmountSet(getValues("amount"));
  };
  if (!event) {
    return "czekaj....";
  }
  return (
    <div className="container-fluid dotation-container">
      <div className="container text-center">
        <div className="tall ng-scope">
          <div className="text-center">
            {errors && errors.amount && (
              <div className="mb-4">
                Przygotowanie i wysyłka Listu od Mikołaja kosztuje 10 złotych.
                Sugerujemy jednak przekazanie darowizny w wysokości 18 złotych
                lub więcej. Każda złotówka jest przeznaczana na pomoc dzieciom,
                które zostały skrzywdzone i bardzo tej pomocy potrzebują. </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-3">
              <PaymentAmount amountVal={event.payament_amount_one} selectedAmount={selectedAmount} handleClick={() =>
                handleSelectPayment(event.payament_amount_one.toString())
              }>
                umożliwia odebranie połączenia <br /> w&nbsp;116 111 - telefonie zaufania  <br/> dla dzieci i&nbsp;młodzieży

              </PaymentAmount>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-3">
              <PaymentAmount amountVal={event.payament_amount_two} selectedAmount={selectedAmount} handleClick={() =>
                handleSelectPayment(event.payament_amount_two.toString())
              }>
               sprawia, że psycholog i prawnik <br/> będą mogli przygotować <br/>dziecko do przesłuchania
              </PaymentAmount>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-6">
              <PaymentAmount selectedAmount={selectedAmount} amountVal={false}
                             handleClick={() => handleSelectPayment("other")} handleOther={handleOther}
                             minimum_amount={event.minimum_amount}>
                Każda darowizna pozwoli <br/>
                nam skutecznie pomagać
                <br/>
                krzywdzonym dzieciom.
              </PaymentAmount>
            </div>
          </div>
          <div className="hidden-lg hidden-md hidden-sm">
            <div className="dot-info">
              Każda darowizna pozwoli nam skutecznie pomagać krzywdzonym
              dzieciom.
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Przelewy24Form register={register} handleSubmit={handleSubmit} watch={watch} errors={errors}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentChooser;
