import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {checkPartnerCode, getCouponSalesForceId, getLetterInfo, postLetterSave,} from "./LetterSaveApi";

const initialState = {
  step: 0,
  status: "idle",
  statusGetLetter: "idle",
  letterId: null,
  submitted: false,
  letterContent: {},
  contactForm: {},
  coupon: null,
  couponState: null,
  partner: null,
  partnerState: null,
};

export const saveLetterAsync = createAsyncThunk(
  "letterorder/saveLetter",
  async (data) => {
    const response = await postLetterSave(data);
    // The value we return becomes the `fulfilled` action payload
    console.log({data});
    console.log(response.data);
    return response.data;
  }
);

export const getLetterInfoAsync = createAsyncThunk(
  "letterorder/getLetterInfo",
  async (data) => {
    const response = await getLetterInfo(data.letterId, data.email);
    // The value we return becomes the `fulfilled` action payload
    console.log(response.data);
    return response.data;
  }
);

export const getCouponSalesForceIdAsync = createAsyncThunk(
  "letterorder/couponSalesForceId",
  async (data) => {
    const response = await getCouponSalesForceId(data.coupon, data.event);
    // The value we return becomes the `fulfilled` action payload
    console.log(response.data);
    return response.data;
  }
);

export const checkPartnerCodeAsync = createAsyncThunk(
  "letterorder/checkPartner",
  async (data) => {
    console.log("data", data);
    const response = await checkPartnerCode(data.partner, data.event);
    // The value we return becomes the `fulfilled` action payload
    console.log(response.data);
    return response.data;
  }
);

export const letterOrderSlice = createSlice({
  name: "letterorder",
  initialState,
  reducers: {
    setSubmitted: (state, action) => {
      state.submitted = action.payload;
    },
    setupLetter: (state, action) => {
      console.log("setupLetter");
      state.letterContent = action.payload;

      state.submitted = true;
      state.step = 1;
    },
    setupContactForm: (state, action) => {
      console.log("setupContactForm");
      state.contactForm = action.payload;

      state.submitted = true;
      state.step = 3;
    },
    changeStep: (state, action) => {
      if (state.step === 3) {
        state.step = 0;
      } else {
        state.step = action.payload;
      }
      if (state.step === 0) {
        // state.submitted = false;
      }
    },
  },
  // getLetterInfoAsync
  extraReducers: (builder) => {
    builder
      .addCase(saveLetterAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(saveLetterAsync.fulfilled, (state, action) => {
        state.status = "ready";
        state.letterId = action.payload.letterId;
      })
        .addCase(getLetterInfoAsync.pending, (state) => {
          state.statusGetLetter = "loading";
        })
        .addCase(getLetterInfoAsync.fulfilled, (state, action) => {
          state.statusGetLetter = "ready";
          state.letterId = action.payload.letter.aggregate_id;
          state.contactForm.sender = action.payload.letter.sender;
        })
        .addCase(checkPartnerCodeAsync.pending, (state) => {
        })
        .addCase(checkPartnerCodeAsync.fulfilled, (state, action) => {
          if (action.payload.partner && action.payload.partner.code && action.payload.partner.usage_limit > 0) {
            state.partner = action.payload.partner;
          } else if (action.payload.partner && action.payload.partner.code && action.payload.partner.usage_limit < 1) {
            state.partnerState = "invalid";
          } else {
            state.partnerState = "error";
          }
        })
        .addCase(getCouponSalesForceIdAsync.pending, (state) => {
        })
      .addCase(getCouponSalesForceIdAsync.fulfilled, (state, action) => {
        if (action.payload.coupon && action.payload.coupon.coupons_left > 0) {
          state.coupon = action.payload.coupon;
          state.couponState = "valid";
        } else if (
          action.payload.coupon &&
          action.payload.coupon.code &&
          action.payload.coupon.coupons_left < 1
        ) {
          state.couponState = "invalid";
        } else {
          state.couponState = "error";
        }
      });
  },
});

export const selectStep = (state) => state.letterorder.step;
export const selectSubmitted = (state) => state.letterorder.submitted;
export const selectLetterContent = (state) => state.letterorder.letterContent;
export const selectContactForm = (state) => state.letterorder.contactForm;
export const selectLetterId = (state) => state.letterorder.letterId;
export const selectCoupon = (state) => state.letterorder.coupon;
export const selectPartner = (state) => state.letterorder.partner;
export const selectCouponState = (state) => state.letterorder.couponState;
export const {changeStep, setupLetter, setupContactForm, setSubmitted} =
    letterOrderSlice.actions;
export default letterOrderSlice.reducer;
