import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import InputLabel from "./InputLabel";
import EmailField from "./EmailField";
import CheckTherms from "./CheckTherms";
import SubmitButton from "./SubmitButton";
import PhoneField from "./PhoneField";
import {setupContactForm} from "../letterOrderSlice";
import PostalCode from "./postal_code/PostalCode";
import ReactGA from "react-ga";


const ContactForm = () => {
  const {
    control,
    register,
    watch,
    handleSubmit,
    setValue,
    unregister,
    reset,
    getValues,

    formState: {errors, touchedFields},
  } = useForm();
  const dispatch = useDispatch();
  // console.log({errors});
  const firstName = watch("sender.first_name");
  const lastName = watch("sender.last_name");
  const sender = watch("sender");
  const convertFirstLetterToUpperCase = (elem, sep) => {
    let clearString = elem.replace(/ +(?= )/g, '');
    let splitStr = clearString.toLowerCase().split(sep);
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join(sep);
  }


  useEffect(() => {
    if (!firstName) return;
    const regex = /(^\w{1})|(\s+\w{1})|(-+\w{1})/g
    // const allChars = "AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻż".split('')
    const firstNameUpper = firstName.replace(regex, (s) => s.toUpperCase());
    setValue("sender.first_name", firstNameUpper);
  }, [firstName]);

  useEffect(() => {
    if (!lastName) return;
    const regex = /(^\w{1})|(\s+\w{1})|(-+\w{1})/g
    const lastNameUpper = lastName.replace(regex, (s) => s.toUpperCase());
    setValue("sender.last_name", lastNameUpper);
  }, [lastName]);

  const onSubmit = (data) => {
    dispatch(setupContactForm(data));
    ReactGA.event({
      category: 'Wyślij list',
      action: "Wypełnij formularz adresowy",
      label: 'Dalej',
    });
    // reset();
    // dispatch(setupLetter(data));
  };
  const copySendarDataToReceiver = () => {
    const seder_data = {...sender};
    delete seder_data.phone;
    delete seder_data.email;
    delete seder_data.first_name;
    delete seder_data.last_name;
    setValue("receiver", seder_data);
  };
  return (
    <div className="container-fluid personal-data-container">
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tall">
            <div className="row">
              <div className="col-md-6">
                <h3 className="marginH3button">Twoje dane kontaktowe</h3>
                <InputLabel name="sender.first_name" label="Imię" register={register} errors={errors}
                            autoComplete="given-name" required={true}/>
                <InputLabel name="sender.last_name" label="Nazwisko" register={register} errors={errors}
                            autoComplete="family-name" required={true}/>
                <InputLabel name="sender.street_address" label="Ulica" register={register} errors={errors}
                            required={true}/>
                <InputLabel name="sender.street_number" label="Nr domu" register={register} errors={errors}
                            required={true}/>
                <InputLabel name="sender.apartment_number" label="Nr mieszkania" register={register} errors={errors}
                            required={false}/>
                <InputLabel name="sender.city" label="Miasto" register={register} errors={errors}
                            autoComplete="address-level2" required={true}/>
                <InputLabel name="sender.postal_code" label="Kod pocztowy" placeholder="00-000" register={register}
                            errors={errors} autoComplete="postal-code" required={true} pattern={/\d{2}-\d{3}/} >
                <PostalCode target="sender" watch={watch} setValue={setValue}/>
              </InputLabel>

              <EmailField register={register} errors={errors} name="sender.email" label="E-mail" autoComplete="email"/>
              <PhoneField name="sender.phone" label="Telefon (nieobowiązkowe)" register={register} errors={errors}

              />
            </div>
            <div className="col-md-6">
              <h3>Dane dziecka</h3>
              <div className="copy-data" onClick={copySendarDataToReceiver}>
                Kopiuj adres z danych kontaktowych
              </div>
              <InputLabel name="receiver.first_name" label="Imię" register={register} errors={errors}
                          autoComplete="given-name" required={true}/>
              <InputLabel name="receiver.last_name" label="Nazwisko" register={register} errors={errors}
                          autoComplete="family-name" required={true}/>
              <InputLabel name="receiver.street_address" label="Ulica" register={register} errors={errors}
                          required={true}/>
              <InputLabel name="receiver.street_number" label="Nr domu" register={register} errors={errors}
                          required={true}/>
              <InputLabel name="receiver.apartment_number" label="Nr mieszkania" register={register} errors={errors}
                          required={false}/>
              <InputLabel name="receiver.city" label="Miasto" register={register} errors={errors}
                          autoComplete="address-level2" required={true}/>
              <InputLabel name="receiver.postal_code" label="Kod pocztowy" placeholder="00-000" register={register}
                          errors={errors} autoComplete="postal-code" required={true} pattern={/\d{2}-\d{3}/}>
                <PostalCode target="receiver" watch={watch} setValue={setValue}/>
              </InputLabel>
            </div>
          </div>
          <CheckTherms register={register} errors={errors}/>
          <SubmitButton/>
      </div>
    </form>
</div>
</div>
)
  ;
};

export default ContactForm;
