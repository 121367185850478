import React from "react";
import BackgroundSlider from "../../features/background_slider/BackgroundSlider";
const LettersContainer = () => {
  return (
    <>
      <div className="container letter-container">
        <div className="container text-center">
          <h2>Jak wygląda list?</h2>
          <p>
            Stwórz niezapomniany list, wykorzystując jedną
            <br />
            z&nbsp;świątecznych grafik, które znani artyści przygotowali dla
            małych
            <br />
            i&nbsp;dużych dzieci.
          </p>
          <BackgroundSlider />
        </div>
      </div>
    </>
  );
};

export default LettersContainer;
