import styled from "styled-components";
const arrowBreakPoint = 768;
export const LetterSlider = styled.div`
  display: flex;
  justify-content: space-around;

  max-width: 800px;
  margin: 0 auto;
`;

const Arrow = styled.div`
  margin-top: 100px;
  cursor: pointer;

  ${({ disable }) => disable && `opacity: 0.3;cursor:default;`}
  @media (min-width: ${arrowBreakPoint}px) {
    display: none;
  }
`;

export const LeftArrow = styled(Arrow)``;

export const RightArrow = styled(Arrow)``;

export const SlideContainer = styled.div`
  position: relative;
  @media (min-width: ${arrowBreakPoint + 1}px) {
    min-height: 290px;
  }

  @media (max-width: ${arrowBreakPoint}px) {
    display: none;
    ${({ current }) => current && `display: block;`}
  }
`;

export const Author = styled.div`
  font-size: 14px;
  max-width: 165px;
  margin-top: 5px;
  margin: 0 auto;
  ${({ isOnForm }) => isOnForm && `color: #000000;`}
  @media (max-width: ${arrowBreakPoint}px) {
    max-width: 100%;
  }
`;

export const SlideImg = styled.img`
  width: 165px;
  height: 218px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: block;
  padding: 4px;
  ${({ isOnForm }) => isOnForm && `cursor: pointer;`}
  ${({ isSelected }) => isSelected && `zoom: 1.1;margin-top:-10px;`}
  @media (max-width: ${arrowBreakPoint}px) {
    width: 100%;
    height: auto;
  }
`;
export const CheckedImg = styled.img`
  position: absolute;
  top: -22px;
  left: calc(50% - 16px);
`;
