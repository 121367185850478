import React from "react";
import { selectEventConfig } from "../../event_fetcher/EventFetcherSlice";
import { useSelector } from "react-redux";

const PaymentInfo = ({ coupon, partner }) => {
  const { event } = useSelector(selectEventConfig);
  return (
      <div className="container-fluid letter-container dar-infor">
        <div className="container text-center">
          {(coupon && coupon.free) || (partner && partner.free) ? (
              <>
                Twój list od Mikołaja jest już gotowy. Dziękujemy za zamówienie!{" "}
                <br/>
                Możesz juz teraz zamówić kolejne listy. Tym razem poprosimy Cię o
                przekazanie darowizny. <br/>
                Sugerujemy przekazanie {event.payament_amount_one} złotych lub więcej. <br/>
                Taka kwota pozwoli nam pracować z dziećmi, które są ofiarami
                przemocy i wykorzystywania.
              </>
          ) : (
              <>
                <h2>Przekaż darowiznę</h2>
                <p>
                  Twój list od Mikołaja jest już gotowy. <br/>
                  Prosimy Cię teraz o przekazanie darowizny.
                  Sugerujemy przekazanie {event.payament_amount_one} złotych lub więcej. <br/>
                  Taka kwota pozwoli nam pracować z dziećmi, które są ofiarami
                  przemocy i wykorzystywania. <br/>
                </p>
                <p>
                  Serdecznie dziękujemy naszym Darczyńcom za wsparcie, <br/>
                  bez którego nie moglibyśmy codziennie pracować <br/>
                  na rzecz naszych podopiecznych. <br/>
                </p>
              </>
          )}
        </div>
      </div>
  );
};

export default PaymentInfo;
