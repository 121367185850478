import React from "react";
import {
  SlideContainer,
  Author,
  SlideImg,
  CheckedImg,
} from "./BackGroundSliderStyled";
import checkedImg from "../../assets/images/check.png";
const Slide = ({
  background,
  current,
  isOnForm,
  isSelected,
  index,
  handleChangeSelectedBackground,
}) => {
  return (
    <SlideContainer current={current}>
      <SlideImg
        src={background.thumbnail_url}
        alt={background.author}
        className="slide-chooser"
        isOnForm={isOnForm}
        isSelected={isSelected}

        onClick={() => handleChangeSelectedBackground(background, index)}
      />
      {isSelected && <CheckedImg src={checkedImg} alt="Background Selected" />}

      <Author isOnForm={isOnForm}>
        {background.author_sex === "m" ? "Autor:" : "Autorka:"}
        <br /> {background.author}
      </Author>
    </SlideContainer>
  );
};

export default Slide;
