import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReactGA from "react-ga";
import rightarrow from "../../assets/images/right.png";
import leftarrow from "../../assets/images/left.png";
import { LetterSlider, LeftArrow, RightArrow } from "./BackGroundSliderStyled";
import Slide from "./Slide";
import { selectEventConfig } from "../event_fetcher/EventFetcherSlice";
const BackgroundSlider = ({ isOnForm, register, setValue, background }) => {
  const { backgrounds } = useSelector(selectEventConfig);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedBackground, setSelectedBackground] = useState(background);

  const handleChangeCurentSlide = (arrow) => {
    if (arrow === "right") {
      if (currentSlide < backgrounds.length - 1) {
        setCurrentSlide(currentSlide + 1);
      }
    }
    if (arrow === "left") {
      if (currentSlide > 0) {
        setCurrentSlide(currentSlide - 1);
      }
    }
  };
  const handleChangeSelectedBackground = (background, index) => {
    if (isOnForm) {
      setSelectedBackground(background.id);
      setValue("background_id", background.id, { shouldValidate: true });
      // console.log(background, index);
      ReactGA.event({
        category: 'Wyślij list',
        action: "Wybierz grafikę i treść listu",
        label: 'Wybierz grafikę',
        value: index+1,
      });
    }
  };
  if (!backgrounds) {
    return "";
  }

  return (
    <>
      {isOnForm && (
        <input
          type="hidden"
          {...register("background_id", { required: true })}
        />
      )}

      <LetterSlider>
        <LeftArrow
          disable={currentSlide === 0}
          onClick={() => handleChangeCurentSlide("left")}
        >
          <img src={leftarrow} alt="w lewo" />
        </LeftArrow>
        {backgrounds.map((background, index) => (
          <Slide
            isOnForm={isOnForm}
            background={background}
            index={index}
            current={index === currentSlide}
            isSelected={background.id === selectedBackground}
            handleChangeSelectedBackground={handleChangeSelectedBackground}
            key={background.id}
          />
        ))}

        <RightArrow
          disable={currentSlide === backgrounds.length - 1}
          onClick={() => handleChangeCurentSlide("right")}
        >
          <img src={rightarrow} alt="w prawo" />
        </RightArrow>
      </LetterSlider>
    </>
  );
};

export default BackgroundSlider;
