import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectEventConfig} from "../../event_fetcher/EventFetcherSlice";
import {useParams} from "react-router-dom";
import {
    saveLetterAsync,
    selectContactForm,
    selectCoupon,
    selectLetterContent,
    selectLetterId,
    selectPartner,
} from "../letterOrderSlice";
import PaymentChooser from "./PaymentChooser";
import PaymentInfo from "./PaymentInfo";

const Payment = () => {
  const dispatch = useDispatch();
  const letterContent = useSelector(selectLetterContent);
  const contactForm = useSelector(selectContactForm);
  const coupon = useSelector(selectCoupon);
  const partner = useSelector(selectPartner);
  const letterId = useSelector(selectLetterId);
  const { event } = useSelector(selectEventConfig);
  let params = useParams();
  useEffect(() => {
    !params.letterId &&
      dispatch(
        saveLetterAsync({
          fields: { ...letterContent },
          ...contactForm,
          coupon: coupon,
          partner: partner,
          event_id: event && event.id,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let paymentForm;
  if (letterId) {
    paymentForm = (
      <PaymentChooser sender={contactForm.sender} letterId={letterId} />
    );
  } else {
      paymentForm = (
          <div className="container text-center">
              <h3>Zapisywanie listu...</h3>
          </div>
      );
  }
    if (coupon && coupon.free) {
        paymentForm = "";
    }
    if (partner && partner.free) {
        paymentForm = "";
    }
    return (
        <div>
            <PaymentInfo coupon={coupon} partner={partner}/>
            {paymentForm}
        </div>
    );
};

export default Payment;
