import React from "react";
import { InfoBox } from "./CheckThermsStyled";
const CheckTherms = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <InfoBox>
          <label>
            Fundacja Dajemy Dzieciom Siłę przetwarza Państwa dane osobowe oraz
            dane osobowe dziecka, na rzecz którego tworzymy list na podstawie
            art. 6 ust. 1 pkt. b i f RODO, tzn. po to, aby przygotować list dla
            dziecka, zarejestrować Państwa darowiznę oraz promować misję
            Fundacji, jaką jest zapewnienie wszystkim dzieciom bezpiecznego
            dzieciństwa.
            <a
              href="https://fdds.pl/polityka-prywatnosci/"
              target="_blank"
              rel="noreferrer"
            >
              Więcej informacji tutaj…
            </a>
          </label>
        </InfoBox>
      </div>
    </div>
  );
};

export default CheckTherms;
