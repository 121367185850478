import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
const ThankYou = () => {
  let { letterId, amount } = useParams();
  const collectGaEvent = () => {
    ReactGA.event({
      category: 'Wyślij list',
      action: "Dziękujemy za zamówienie",
      label: "Wyślij kolejny list",
    });

  }
  return (
    <>
      <div className="container-fluid subpage thankyou">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="text-center">
                DZIĘKUJEMY!
                <br />
                Twój list jest już gotowy
              </h2>
              <h3 className="text-center">
                Teraz pozostaje tylko czekać aż dotrze do małego adresata <br />
                i wywoła uśmiech na jego twarzy.
              </h3>
              <Link
                onClick={collectGaEvent}

                to="/zamow-list"
                className="wyslij_small ng-scope"
                style={{
                  position: "static",
                  display: "inline-block",
                  color: "#fff",
                }}
              >
                {" "}
                Wyślij kolejny list
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
